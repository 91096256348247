import React, { useState } from "react";

const ModelUploadPrescription = () => {

    const [selectedImages, setSelectedImages] = useState([]);

    const data = [
        { id: 1, eye: 'SPHERICAL', rightEye: '-3.50', leftEye: '-4.00' },
        { id: 2, eye: 'CYLINDRICAL', rightEye: '-0.75', leftEye: '-1.25' },
        { id: 3, eye: 'AXIS', rightEye: '150', leftEye: '130' },
        { id: 4, eye: 'AP', rightEye: '-', leftEye: '-' },
    ];

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const imageFiles = files.filter(file =>
            ["image/jpeg", "image/png", "image/gif"].includes(file.type)
        );

        if (imageFiles.length) {
            const imagesArray = imageFiles.map((file) => {
                return {
                    url: URL.createObjectURL(file),
                    name: file.name,
                };
            });
            setSelectedImages((prevImages) => [...prevImages, ...imagesArray]);
        }
    };

    const removeImage = (index) => {
        setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };


    return (
        <div>
            <div className="flex-ord-sold mdl-manual-pre">
                <div className="review-box-image order-image-box">
                    <img className="favourite-img fav-image-ord"
                        src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                        alt=""
                    />
                </div>
                <div className="review-box-detail item-details-width-ord">
                    <div className="review-box-detail-line-1">
                        <div className="name-top-item">

                            Lenskart Hustlr Eyeglasses
                            + Hydrophobic Anti-Glare

                        </div>
                    </div>
                    
                </div>
            </div>

                <div className="upload-top-pres">UPLOAD YOUR PRESCRIPTIONS</div>
            <div className="">
                <div className="image-uploader">
                    <div data-filetype=".jpg" class="filepicker-file-icon">
                        <div className="jpg-color-file">.JPG</div>
                    </div>
                    <div data-filetype=".png" class="filepicker-file-icon">
                    <div className="jpg-color-file">.PNG</div>
                    </div>
                    <div data-filetype=".gif" class="filepicker-file-icon">
                    <div className="jpg-color-file">.GIF</div>
                    </div>

                    <input type="file"
                        accept=".jpg,.jpeg,.png,.gif"
                        multiple
                        onChange={handleFileChange}
                        class="file-input"
                    />

                    <div className="image-preview-container">
                        {selectedImages.map((image, index) => (
                            <div key={index} className="image-preview">
                                <img src={image.url} alt={image.name} />
                                <button onClick={() => removeImage(index)} className="remove-btn">
                                    Remove
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="drop-files-upload">Drop files here to upload/ Click to Upload</div>
                </div>


            </div>

        </div>

    );
};

export default ModelUploadPrescription;
