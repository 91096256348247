
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import WebTemplate from "../../../../API/API_SERVISES/WebTemplate";
// import styles from "../Css/HomePage.Module.css"
// import styles1 from "../Css/Edit.Module.css"
// import styles2 from "../Css/Draggable.Module.css"



const FAQ = (props) => {
  const [apiData, setApiData] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [styleArray, setStylesArray] = useState([]);



  useEffect(() => {
    console.log("styleArray", styleArray);
    console.log("apiData", apiData);
    console.log("droppedItems", droppedItems);
  }, [apiData, droppedItems, styleArray]);

  const showTemplateData = async (e) => {
    debugger
    const filteredData = [{
      "fieldName": "name",
      "operatorName": "equal",
      "compareValue": "FAQ"
    }];
    var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
    debugger
    if (getdata) {
      if (getdata.jsonStructure) {
        try {
          const decodedData = decodeURIComponent(getdata.jsonStructure)
          const parsedData = JSON.parse(decodedData);
          // getdata.jsonStructure = parsedData
          setDroppedItems(parsedData);
          if (getdata.textContents) {
            const decodedData = decodeURIComponent(getdata.textContents)
            const parsedData = JSON.parse(decodedData);
            // getdata.jsonStructure = parsedData
            setStylesArray(parsedData);
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
          setDroppedItems([]);
        }
      } else {
        setDroppedItems([]);
      }
      var templateInnerHtml = getdata.textContents;
      var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
      getdata.textContents = templateInnerHtmlDecode

      setApiData(getdata);

      // setDataHtml(getdata)

    } else {
      // document.getElementById("drop-target-innerHtml").outerHTML = "";
      setDroppedItems([]);
    }
  }
  useEffect(() => {
    if (props.currentUser) {
      showTemplateData()
    }
  }, [props.currentUser]);
  useEffect(() => {
    // Debugging
    debugger;

    // Use a for loop to iterate over stylesArray
    for (let index = 0; index < styleArray.length; index++) {
      const styleObject = styleArray[index];
      console.log(styleObject.id);

      const element = document.getElementById(styleObject.id);

      if (element) {
        for (const [key, value] of Object.entries(styleObject.style)) {
          // Convert kebab-case to camelCase for inline styles
          const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
          element.style[camelCaseKey] = value;
        }
      }
    }
  }, [styleArray]);


  const renderDroppedItems = () => {
    return droppedItems.length > 0 && droppedItems.some(item => item.items.length > 0) ? (
      droppedItems.map((item, index) => {
        if (item.items.length > 0) {
          return (
            <div key={index} dangerouslySetInnerHTML={{ __html: item.textContents }} />
          );
        }
        return null;
      })
    ) : null;

  };

  return (
    <div style={{ backgroundColor: "#fff", marginTop: "15px" }} >
      {renderDroppedItems()}
    </div>
  )
}


export default FAQ;