import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Navigation, Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module
import 'swiper/css/navigation';
import 'swiper/css/pagination'; // Import pagination styles

const Testimonial = () => {
    return (
        <section className="Testimonialcontainer">
            <Swiper
                slidesPerView={1}
                grabCursor={true}
                loop={true}
                pagination={true} // Enable pagination
                navigation={true} // Enable navigation
                modules={[Pagination, Navigation, Autoplay]} // Register modules
                autoplay={{ // Configure autoplay
                    delay: 3000, // 3 seconds delay
                    disableOnInteraction: false, // Autoplay continues after user interactions
                }}
                className="testimonial mySwiper"
            >
                <SwiperSlide className="slide">
                    <img src="https://i.ibb.co/3m5x6B5/people6.jpg" alt="" className="image" />
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam,
                        saepe provident dolorem a quaerat quo error facere nihil deleniti
                        eligendi ipsum adipisci, fugit, architecto amet asperiores
                        doloremque deserunt eum nemo.
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Marnie Lotter</span>
                        <span className="job">Web Developer</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                    <img src="https://i.ibb.co/hFVbbty/people3.jpg" alt="" className="image" />
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam,
                        saepe provident dolorem a quaerat quo error facere nihil deleniti
                        eligendi ipsum adipisci, fugit, architecto amet asperiores
                        doloremque deserunt eum nemo.
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Marnie Lotter</span>
                        <span className="job">Web Developer</span>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                    <img src="https://i.ibb.co/Lh9gfFZ/people2.jpg" alt="" className="image" />
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam,
                        saepe provident dolorem a quaerat quo error facere nihil deleniti
                        eligendi ipsum adipisci, fugit, architecto amet asperiores
                        doloremque deserunt eum nemo.
                    </p>
                    <i className="bx bxs-quote-alt-left quote-icon"></i>
                    <div className="details">
                        <span className="name">Marnie Lotter</span>
                        <span className="job">Web Developer</span>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
}

export default Testimonial;
