import React, { useState } from "react";

const ModelRequestACallBack = () => {

    return (
        <div>
            <div className="flex-ord-sold mdl-manual-pre">
                <div className="review-box-image order-image-box">
                    <img className="favourite-img fav-image-ord"
                        src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                        alt=""
                    />
                </div>
                <div className="review-box-detail">
                    <div className="review-box-detail-line-1">
                        <div className="name-top-item">
                            Lenskart Hustlr Eyeglasses
                            + Hydrophobic Anti-Glare
                        </div>
                    </div>
                </div>
            </div>

            <div className="learn-read-prescription">
                <div>
                    <div className="right-checked-circle"><i class="fa-regular fa-circle-check"></i></div>
                    <div className="succesfully-athenticate">Succesfully Authenticated!</div>
                    <div className="review-submit">Our executive will get in touch with you within</div>
                    <div className="review-submit">24 hours to help you with your prescription.</div>

                    <div className="continue-btn">
                        <button className="btn-pay-now continue-btn-select">CONTINUE</button>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default ModelRequestACallBack;
