import React, { useState } from "react";

const ModelSavedPrescription = () => {

    const [showTable, setShowTable] = useState(false);


    const data = [
        { id: 1, eye: 'SPHERICAL', rightEye: '-3.50', leftEye: '-4.00' },
        { id: 2, eye: 'CYLINDRICAL', rightEye: '-0.75', leftEye: '-1.25' },
        { id: 3, eye: 'AXIS', rightEye: '150', leftEye: '130' },
        { id: 4, eye: 'AP', rightEye: '-', leftEye: '-' },
    ];


    const handleSelectClick = () => {
        setShowTable(true);
    };

    // Handle click to go back (hide the table)
    const handleBackClick = () => {
        setShowTable(false);
    };


    return (
        <div>
            <div className="flex-ord-sold mdl-manual-pre">
                <div className="review-box-image order-image-box">
                    <img className="favourite-img fav-image-ord"
                        src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                        alt=""
                    />
                </div>
                <div className="review-box-detail item-details-width-ord">
                    <div className="review-box-detail-line-1">
                        <div className="name-top-item">

                            Lenskart Hustlr Eyeglasses
                            + Hydrophobic Anti-Glare

                        </div>
                    </div>
                    {/* <div className="review-box-detail-line-2">
                        <div className="lens-txt">
                            Lens :{" "}
                            <div className="review-product-name">
                                Sold by Lenskart Solutions Pvt Ltd.
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "60px",
                            }}
                        >
                            {" "}
                        </div>
                    </div> */}
                    {/* <p className="review-price-item">
                        <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                    </p> */}
                </div>
            </div>

            <div className="learn-read-prescription">
                {!showTable && (
                    <div>
                        <div className="saved-prescription-your">SELECT FROM YOUR SAVED PRESCRIPTIONS</div>
                        <div className="flex-prescripton">
                            <div className="left-prescription-width">
                                <div className="flex-power-type">
                                    <p className="prescription-name-user">NAME : </p>
                                    <p className="prescription-name-user">LENSKART USER</p>
                                </div>

                                <div className="">
                                    <button className="btn-pay-now select-btn-pres" onClick={handleSelectClick}>SELECT</button>
                                </div>


                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead className="header-prescription-eye">
                                        <tr>
                                            <th className="header-left-right-eye">EYE</th>
                                            <th className="header-left-right-eye">RIGHT EYE</th>
                                            <th className="header-left-right-eye">LEFT EYE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(row => (
                                            <tr key={row.id}>
                                                <td className="table-left-right-data">{row.eye}</td>
                                                <td className="table-prescription-data">{row.rightEye}</td>
                                                <td className="table-prescription-data">{row.leftEye}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                )}
                {showTable && (
                    <div>
                        <div className="right-checked-circle"><i class="fa-regular fa-circle-check"></i></div>
                        <div className="review-submit">Please review your prescription before submitting</div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className="header-prescription-eye">
                                <tr>
                                    <th className="header-left-right-eye">EYE</th>
                                    <th className="header-left-right-eye">RIGHT EYE</th>
                                    <th className="header-left-right-eye">LEFT EYE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(row => (
                                    <tr key={row.id}>
                                        <td className="table-left-right-data">{row.eye}</td>
                                        <td className="table-prescription-data">{row.rightEye}</td>
                                        <td className="table-prescription-data">{row.leftEye}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="continue-btn">
                            <button className="btn-pay-now continue-btn-select">CONTINUE</button>
                        </div>
                        <div className="back-color-mdl" onClick={handleBackClick}>
                            BACK
                        </div>
                    </div>
                )}
            </div>

        </div>

    );
};

export default ModelSavedPrescription;
