import React, { useEffect, useState } from "react";
import OrderApi from "../../../API/API_SERVISES/Order";

const ModelPrescriptionManually = ({ onSelect, onContinue, isSubmitted }) => {

    const [showTable, setShowTable] = useState(false);
    const data = [
        { id: 1, eye: 'SPHERICAL', rightEye: '-3.50', leftEye: '-4.00' },

    ];

    const [negativeOptions] = useState([
        '-13.00', '-12.75', '-12.50', '-12.00', '-11.75', '-11.50'
    ]);

    const [positiveOptions] = useState([
        '+0.25', '+0.50', '+0.75', '+1.00', '+1.25', '+1.50'
    ]);

    const handleSubmitClick = () => {
        setShowTable(true);
    };

    // Handle click to go back (hide the table)
    const handleBackClick = () => {
        setShowTable(false);
    };

    /********************************************************** */

    // const [inputValues, setInputValues] = useState({
    //     LE_SPH: "",
    //     LE_CYL: "",
    //     LE_AXIS: "",
    //     LE_ADD: "",
    //     RE_SPH: "",
    //     RE_CYL: "",
    //     RE_AXIS: "",
    //     RE_ADD: "",
    // });

    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     console.log(`Changing ${name} to ${value}`);
    //     setInputValues((prevValues) => ({
    //         ...prevValues,
    //         [name]: value,
    //     }));

    // };


    // function generateRanges(sp1, sp2, intervals) {
    //     debugger

    //     const sp1Array = sp1.split(",").map(num => {
    //         const parsed = parseFloat(num);
    //         if (isNaN(parsed)) {
    //             throw new Error(`Invalid number in sp1: ${num}`);
    //         }
    //         return parsed;
    //     });
    //     const sp2Array = sp2.split(",").map(Number);
    //     const intervalArray = intervals.split(",").map(parseFloat);


    //     const allRanges = [];

    //     for (let i = 0; i <= sp1Array.length; i++) {
    //         const currentInterval = intervalArray[0];
    //         const range = generateRange(sp1Array[i], sp2Array[i], currentInterval);
    //         allRanges.push(...range);
    //     }
    //     allRanges.sort((a, b) => a - b);

    //     console.log("allRanges", allRanges);
    //     return allRanges;
    // }

    // function generateRange(start, end, interval) {
    //     const step = parseFloat(interval);


    //     const result = [];


    //     for (let value = start; value <= end; value += step) {

    //         const roundedValue = Math.round(value * 100) / 100;

    //         result.push(roundedValue);
    //     }

    //     return result;
    // }

    // function generateIntervalValues(inputString) {
    //     return inputString.split(",").map(item => item.trim());
    // }


    // useEffect(() => {
    //     const fetchData = async () => {
    //         const lens = await OrderApi.getLensMasterSettAndFeatures(item.d2Id || item.d2_Id);
    //         setOptionsSph(lens)
    //         console.log("lens", lens);
    //         console.log(item);

    //         if (item.productCategory == 2) {

    //             //*********** Sph ****************** */
    //             const DataSphRight = generateRanges((lens.r_SPH_From), (lens.r_SPH_To), lens.r_SPH_Interval);
    //             setIntervalValuesSphRight(DataSphRight)

    //             const DataSphLeft = generateRanges((lens.l_SPH_From), (lens.l_SPH_To), lens.l_SPH_Interval);
    //             setIntervalValuesSphLeft(DataSphLeft)

    //             //************** Cyl ****************** */

    //             const DataCylRight = generateRanges((lens.r_CYL_From), (lens.r_CYL_To), lens.r_CYL_Interval);
    //             setIntervalValuesCylRight(DataCylRight)

    //             const DataCylLeft = generateRanges((lens.l_CYL_From), (lens.l_CYL_To), lens.l_CYL_Interval);
    //             setIntervalValuesCylLeft(DataCylLeft)

    //             //***************** Axis ******************* */
    //             const DataAxisRight = generateRanges((lens.r_AXIS_From), (lens.r_AXIS_To), lens.r_AXIS_Interval);
    //             setIntervalValuesAxisRight(DataAxisRight)

    //             const DataAxisLeft = generateRanges((lens.l_AXIS_From), (lens.l_AXIS_To), lens.l_AXIS_Interval);
    //             setIntervalValuesAxisLeft(DataAxisLeft)

    //             //************************* Add ****************************** */

    //             const DataAddRight = generateRanges((lens.r_Add_From), (lens.r_Add_To), "0.25");
    //             setIntervalValuesAddRight(DataAddRight)

    //             const DataAddLeft = generateRanges((lens.l_Add_From), (lens.l_Add_To), "0.25");
    //             setIntervalValuesAddLeft(DataAddLeft)
    //         }

    //     };
    //     fetchData();
    // }, []);


    /*********************************************************** */

    const [selectedValueRight, setSelectedValueRight] = useState('Select');
    const [isOpenRight, setIsOpenRight] = useState(false);

    const toggleDropdownRight = () => {
        setIsOpenRight(!isOpenRight);
    };

    const handleSelectRight = (value) => {
        setSelectedValueRight(value);
        if (typeof onSelect === 'function') {
            onSelect(value);
        }
        setIsOpenRight(false);
    };

    /************************************************************* */
    const [selectedValueLeft, setSelectedValueLeft] = useState('Select');
    const [isOpenLeft, setIsOpenLeft] = useState(false);

    const toggleDropdownLeft = () => {
        setIsOpenLeft(!isOpenLeft);
    };

    const handleSelectLeft = (value) => {
        setSelectedValueLeft(value);
        if (typeof onSelect === 'function') {
            onSelect(value);
        }
        setIsOpenLeft(false);
    };

    return (
        <div>
            <div className="flex-ord-sold mdl-manual-pre">
                <div className="review-box-image order-image-box">
                    <img className="favourite-img fav-image-ord"
                        src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                        alt=""
                    />
                </div>
                <div className="review-box-detail item-details-width-ord">
                    <div className="review-box-detail-line-1">
                        <div className="name-top-item">

                            Lenskart Hustlr Eyeglasses
                            + Hydrophobic Anti-Glare

                        </div>
                    </div>
                </div>
            </div>

            <div className="learn-read-prescription">
                {!showTable && (
                    <div>
                        <div className="power-details-pres">
                            <div className="add-power-details">Add Power Details</div>
                            <div className="rigt-leran-prescription">LEARN HOW TO READ PRESCRIPTION</div>
                        </div>
                        <table className="table-head-model-top" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className="header-prescription-eye">
                                <tr>
                                    <th className="header-left-right-eye">EYE</th>
                                    <th className="header-left-right-eye">RIGHT EYE</th>
                                    <th className="header-left-right-eye">LEFT EYE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(row => (
                                    <tr key={row.id}>
                                        <td className="table-left-right-data">{row.eye}</td>
                                        <td className="table-prescription-data">
                                            <div className="dd-container-pres">
                                                <div className="custom-dropdown-pres cl-dd">
                                                    <div
                                                        className={`cl-dd dropdown-container-pres ${isOpenRight ? 'show' : ''}`}
                                                        onClick={toggleDropdownRight}
                                                    >
                                                        <div className="dropdown-display-pres clicked cl-dd">
                                                            <span className="selected-value-pres">{selectedValueRight}</span>
                                                            <i
                                                                aria-hidden="true"
                                                                className={`fa-solid fa-angle-${isOpenRight ? 'up' : 'down'} cl-dd`}
                                                            />

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {isOpenRight && (
                                                <div className="dropdown-list-pres negative positive both">
                                                    <div className="cl-power-list">
                                                        <div className="top-pres-Zero">0.00</div>
                                                        <div className="list-container" style={{ display: 'flex' }}>
                                                            <div className="negative-list" style={{ flex: 1 }}>
                                                                <ul className="list pull-left zero full-width">
                                                                    {negativeOptions.map((option, index) => (
                                                                        <li key={index} onClick={() => handleSelectRight(option)}>
                                                                            <div>{option}</div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="positive-list" style={{ flex: 1 }}>
                                                                <ul className="list pull-left zero full-width">
                                                                    {positiveOptions.map((option, index) => (
                                                                        <li key={index} onClick={() => handleSelectRight(option)}>
                                                                            <div>{option}</div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                        <td className="table-prescription-data">
                                            <div className="dd-container-pres">
                                                <div className="custom-dropdown-pres cl-dd">
                                                    <div
                                                        className={`cl-dd dropdown-container-pres ${isOpenLeft ? 'show' : ''}`}
                                                        onClick={toggleDropdownLeft}
                                                    >
                                                        <div className="dropdown-display-pres clicked cl-dd">
                                                            <span className="selected-value-pres">{selectedValueLeft}</span>
                                                            <i
                                                                aria-hidden="true"
                                                                className={`fa-solid fa-angle-${isOpenLeft ? 'up' : 'down'} cl-dd`}
                                                            />

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {isOpenLeft && (
                                                <div className="dropdown-list-pres negative positive both">
                                                    <div className="cl-power-list">
                                                        <div className="top-pres-Zero">0.00</div>
                                                        <div className="list-container" style={{ display: 'flex' }}>
                                                            <div className="negative-list" style={{ flex: 1 }}>
                                                                <ul className="list pull-left zero full-width">
                                                                    {negativeOptions.map((option, index) => (
                                                                        <li key={index} onClick={() => handleSelectLeft(option)}>
                                                                            <div>{option}</div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="positive-list" style={{ flex: 1 }}>
                                                                <ul className="list pull-left zero full-width">
                                                                    {positiveOptions.map((option, index) => (
                                                                        <li key={index} onClick={() => handleSelectLeft(option)}>
                                                                            <div>{option}</div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="flex-cyl-power">
                            <div>
                                <input className="cyl-checkbox" type="checkbox" />
                            </div>
                            <div className="cyl-power-pres">I Have Cylindrical (CYL) Power</div>
                        </div>
                        <div className="submit-eye-power">
                            <button className="btn-pay-now submit-btn-eye" onClick={handleSubmitClick}>SUBMIT EYE-POWER</button>
                        </div>
                    </div>
                )}


                {showTable && (
                    <div>
                        <div className="right-checked-circle"><i class="fa-regular fa-circle-check"></i></div>
                        <div className="review-submit">Please review your prescription before submitting</div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className="header-prescription-eye">
                                <tr>
                                    <th className="header-left-right-eye">EYE</th>
                                    <th className="header-left-right-eye">RIGHT EYE</th>
                                    <th className="header-left-right-eye">LEFT EYE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(row => (
                                    <tr key={row.id}>
                                        <td className="table-left-right-data">{row.eye}</td>
                                        <td className="table-prescription-data">{row.rightEye}</td>
                                        <td className="table-prescription-data">{row.leftEye}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="continue-btn">
                            <button className="btn-pay-now continue-btn-select" onClick={onContinue}>CONTINUE</button>
                        </div>
                        <div className="back-color-mdl" onClick={handleBackClick}>
                            BACK
                        </div>
                    </div>
                )}
            </div>

        </div>

    );
};

export default ModelPrescriptionManually;
