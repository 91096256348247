import React, { useEffect, useRef, useState } from 'react'
import Header from '../header/Header'
import storeimg from "../../../Assets/Image_lc/review.png"
import BookAppoientment from '../BookAppoientment/BookAppoientment'
import Footer from '../Footer/Footer'
import { Icon } from '@iconify/react/dist/iconify.js'
import Productcard from './Productcard/Productcard'

const StoreHome = () => {
    const [selecteditem, setselecteditem] = useState(null)
    const [modal, setmodal] = useState(false)
    const [selectedtab, setselectedtab] = useState(0)
    const [DeviceType, setDeviceType] = useState(null)

    const review = [
        {
            message: "Wow so nice I so happy this product",
            icon: "",
            name: "Review"
        },
        {
            message: "Wow so nice I so happy this product",
            icon: "",
            name: "Review"
        },
        {
            message: "Wow so nice I so happy this product",
            icon: "",
            name: "Review"
        },
        {
            message: "Wow so nice I so happy this product",
            icon: "",
            name: "Review"
        }
    ]


    const storesData = [
        {
            name: "Chhatarpur",
            address:
                "Property Bearing No 14, Ground Floor, khasra No 619/10 & Old No 619/21, Situated in Extended Abadi of Lal Dora of Village Chhatarpur, Tehsil Hauz Khas, Mehrauli, New Delhi, 110074",
            hours: "11:00 AM to 09:30 PM",
            phone: "+91 8744082080",
            rating: "4.5",
            reviews: "522",
            image:
                "https://www.lenskart.com/stores/_next/image?url=https%3A%2F%2Fmy-lkstore.lenskart.com%2Fstore_locator_image%2FStore_Static_Image%2FLenskartStoreFrontImageCompressed.png&w=1200&q=75"
                ,
        },
        {
            name: "Jawahar Nagar",
            address:
                "Shop No 2, UA/29 Bungalow Road, Opposite Hansraj Girls Hostel, Jawahar Nagar, Delhi, 110007",
            hours: "11:00 AM to 10:00 PM",
            phone: "+91 8929811423",
            rating: "4.8",
            reviews: "1622",
            image:
                "https://www.lenskart.com/stores/_next/image?url=https%3A%2F%2Fmy-lkstore.lenskart.com%2Fstore_locator_image%2FLKST423%2F2.jpeg&w=1200&q=75",
            freeGift: "Free Anti Fog Spray",
            isOpen: true,
        },
        {
            name: "Paschim Vihar",
            address: "Shop No A3/182, Ground Floor, Paschim Vihar, New Delhi, 110063",
            hours: "11:00 AM to 09:30 PM",
            phone: "+91 7428891110",
            rating: "4.8",
            reviews: "981",
            image:
                "https://www.lenskart.com/stores/_next/image?url=https%3A%2F%2Fmy-lkstore.lenskart.com%2Fstore_locator_image%2FLKST110%2FWhatsApp%2520Image%25202019-12-20%2520at%252011.12.43%2520AM.jpeg&w=1920&q=75",
            freeGift: "",
            isOpen: true,
        },
    ];
    const device = navigator.userAgent.toLowerCase()
    console.log(device)
    useEffect(() => {
        const checkDeviceType = () => {
            const width = window.innerWidth;
            if (width <= 768) {
                setDeviceType("Mobile");
            } else if (width > 768 && width <= 1200) {
                setDeviceType("Tablet");
            } else {
                setDeviceType("Desktop/Laptop");
            }
        };

        checkDeviceType();
        window.addEventListener("resize", checkDeviceType);

        return () => window.removeEventListener("resize", checkDeviceType);
    }, [])

    useEffect(() => {
        console.log(DeviceType)
    }, [DeviceType]);

    const productdata = [
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        },
        {
            name: "Lenskart Air Online",
            size: "Narrow",
            gender: "Unisex",
            price: "2000",
            buttonTitle: "Reserve at Store",
            image: ""
        }
    ]
    const tabcontent = useRef([
        {
            title: "Review",
            data: (
                <div className="container-product-card">
                    <div className="reviews-section">
                        <h1 className="reviews-title">Reviews</h1>
                        <div className="reviews-list">
                            {review.map((item) => {
                                return (
                                    <div className="review-card">
                                        <p className="review-text">{item.message}</p>

                                        <h2 className="review-name">
                                            <img className="review-image" src={storeimg} alt="Review" />
                                            {item.name}
                                        </h2>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="about-store">
                        <h1 className="about-store-title">About Lenskart Optical Store at Chhatarpur</h1>
                        <p className="about-store-text">
                            Lenskart Chhatarpur eyewear store is located at New Delhi. It is one of the best optical stores in New Delhi with immense number of Eyeglasses, sunglasses & contact lens options to choose from. The store offers free eye check up to its customers which is performed by certified Optometrist. The free eye test service is available across all stores in India. If you are searching for the best optician near you then Lenskart Chhatarpur is the perfect choice for you. The address of this store is Property Bearing No 14, Ground Floor, khasra No 619/10 & Old No 619/21, Situated in Extended Abadi of Lal Dora of Village Chhatarpur, Tehsil Hauz Khas, Mehrauli, New Delhi, 110074, Chhatarpur, New Delhi, Delhi - 110074.
                        </p>
                    </div>

                    <div className="nearby-stores">
                        <h2 className="nearby-stores-title">Other Store Nearby</h2>

                        <div className="store-list">
                            {storesData.map((item) => {
                                return (
                                    <div className="store-item" key={item.name}>
                                        <h2 className={`store-item-name`}>{item.name}</h2>
                                        <p className={`para-store-item`}>{item.address}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: "Product",
            data: (
                <>
                    <div className={`container-product-card`}>
                        {/* -------searchbox-------- */}
                        <div className={'searchbox'}>
                            <span className={`searchbox-span`}>
                                <Icon icon="weui:search-outlined" style={{ color: "#212529" }} />
                            </span>
                            <input className={`searchbox-input`} name='searchproduct' type='text' placeholder='Search by product id/brand/name and more' />
                        </div>


                        <div className={`storehome-all-product`}>
                            {
                                productdata.map((product) => {
                                    return (
                                        <div style={{ margin: '10px' }}>
                                            <Productcard product={product} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            )
        }
    ])



    useEffect(() => {
        const location = window.location
        const lastSegment = location.pathname.split('/').pop();
        const decodedString = decodeURIComponent(lastSegment);
        const selectedsetore = storesData.find((item) => item.name == decodedString)
        console.log(window.location)
        console.log(decodedString)
        console.log(selectedsetore)
        if (selectedsetore) {
            setselecteditem(selectedsetore)
        }
    }, []);

    const box = (top = "Hours", bottom = "11:00 AM - 09:30 PM") => {

        return (
            <>
                <div className="hours-container">
                    <p>{top}</p>
                    <p className="hours-text">{bottom}</p>
                </div>

            </>
        )
    }

    const Allstore = [
        {
            name: "Chhatarpur",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.4350646364915!2d77.18227857456729!3d28.49655539024421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f1836afcde7%3A0x46720f62783e5352!2sLenskart.com%20at%20Chhatarpur%2C%20Delhi!5e0!3m2!1sen!2sin!4v1731415846021!5m2!1sen!2sin"
        },
        {
            name: "Jawahar Nagar",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.3115580091235!2d77.20551207457478!3d28.68032538191038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd6543d80cbf%3A0x5b5300227eabc7a3!2sLenskart.com%20at%20Kamla%20Nagar%2C%20Delhi!5e0!3m2!1sen!2sin!4v1731416682704!5m2!1sen!2sin"
        },
        {
            name: "Paschim Vihar",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.6613995350012!2d77.10373487457433!3d28.66985548238647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a27af074692537b%3A0x5c3cc90ce00cd942!2sLenskart.com%20at%20Paschim%20Vihar!5e0!3m2!1sen!2sin!4v1731479016661!5m2!1sen!2sin"
        }

    ]

    const handleappoientment = () => {
        setmodal(true);
    }

    const [map, setmap] = useState(null)
    useEffect(() => {
        console.log(selecteditem);

        const mapname = Allstore.find((item) => item.name == selecteditem?.name)
        setmap(mapname?.iframe)

    }, [selecteditem]);
    return (
        <>
            <Header />

            <div className={`storehomepage`}>
                <div className="section-container">
                    <div className="image-container">
                        <img
                            src={selecteditem?.image}
                            alt="Selected"
                            className="image-container-img"
                        />
                    </div>

                    <div className="content-container">
                        <h1 className="title-store-locator">{selecteditem?.name}</h1>
                        <p className="address">{selecteditem?.address}</p>
                        <div className="box-container">
                            {box()}
                            {box("Services", "Eye Test, Repair, Purchase")}
                        </div>

                        {/* ---------EndPart----------------- */}
                        <div className="additional-section">
                            <div className="offer-banner">
                                <p>Redeem FREE Anti Fog Spray at next step!</p>
                            </div>

                            <div className="button-container">
                                <button onClick={()=> setmodal(true)} className="button book-button">

                                    Book Appointment
                                </button>
                                <button className="button direction-button">
                                    {/* <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" id="Capa_1" width="15px" height="15px" viewBox="0 0 32.743 32.743" xml:space="preserve">
                                            <g>
                                                <path d="M32.743,0l-12,32.742L18.182,14.56L0,12L32.743,0z" />
                                            </g>
                                        </svg>
                                    </span> */}
                                    Direction
                                </button>
                                <button className="button call-button">

                                    {/* <spna>
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" id="Capa_1" width="15px" height="15px" viewBox="0 0 414.937 414.937" xml:space="preserve">
                                            <g>
                                                <path d="M159.138,256.452c37.217,36.944,80.295,72.236,97.207,55.195c24.215-24.392,39.12-45.614,92.854-2.761   c53.734,42.874,12.696,71.727-10.757,95.363c-27.064,27.269-128.432,1.911-228.909-97.804C9.062,206.71-17.07,105.54,10.014,78.258   c23.46-23.637,52.006-64.879,95.254-11.458c43.269,53.394,22.161,68.462-2.054,92.861   C86.31,176.695,121.915,219.501,159.138,256.452z M213.104,80.203c0,0-11.227-1.754-19.088,6.113   c-8.092,8.092-8.445,22.032,0.082,30.552c5.039,5.039,12.145,6.113,12.145,6.113c13.852,2.598,34.728,6.997,56.944,29.206   c22.209,22.208,26.608,43.084,29.206,56.943c0,0,1.074,7.106,6.113,12.145c8.521,8.521,22.46,8.174,30.552,0.082   c7.861-7.86,6.113-19.087,6.113-19.087c-4.399-28.057-17.999-57.365-41.351-80.716C270.462,98.203,241.153,84.609,213.104,80.203z    M318.415,96.958c40.719,40.719,58.079,86.932,52.428,124.379c0,0-1.972,11.859,5.773,19.604   c8.718,8.718,22.535,8.215,30.695,0.062c5.243-5.243,6.385-13.777,6.385-13.777c4.672-32.361-1.203-97.464-64.647-160.901   C285.605,2.887,220.509-2.988,188.147,1.677c0,0-8.527,1.136-13.777,6.385c-8.16,8.16-8.656,21.978,0.061,30.695   c7.746,7.746,19.604,5.773,19.604,5.773C231.484,38.879,277.696,56.24,318.415,96.958z" />
                                            </g>
                                        </svg>
                                    </spna> */}
                                    Call
                                </button>
                                <button className="button help-button">

                                    {/* <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none">
                                            <g id="Warning / Help">
                                                <path id="Vector" d="M8.19531 8.76498C8.42304 8.06326 8.84053 7.43829 9.40137 6.95899C9.96221 6.47968 10.6444 6.16501 11.373 6.0494C12.1017 5.9338 12.8486 6.02202 13.5303 6.3042C14.2119 6.58637 14.8016 7.05166 15.2354 7.64844C15.6691 8.24521 15.9295 8.95008 15.9875 9.68554C16.0455 10.421 15.8985 11.1581 15.5636 11.8154C15.2287 12.4728 14.7192 13.0251 14.0901 13.4106C13.4611 13.7961 12.7377 14.0002 12 14.0002V14.9998M12.0498 19V19.1L11.9502 19.1002V19H12.0498Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                        </svg>
                                    </span> */}
                                    Need Help?
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------last---------- */}
                <div className={`lastmsain`}>
                    {/* ------Tab-button------ */}
                    <div className={`tab-content`}>
                        <div className={`tab-button`}>
                            <button onClick={() => setselectedtab(0)} className={`tab-single-button ${selectedtab == 0 ? 'active1' : null}`}>Review</button>
                            <button onClick={() => setselectedtab(1)} className={`tab-single-button ${selectedtab == 1 ? 'active1' : null}`}>Product</button>
                        </div>
                        {tabcontent.current[selectedtab].data}
                    </div>
                    <div className="map-container">
                        <iframe
                            src={map}
                            width="100%"
                            height="400px"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>

                        <div className={`antifog-spray`}>
                            <h1>
                                Get Free Eye Test & Anti Fog Spray
                            </h1>
                            <p className={`para`}>Book free eye test appointment at your nearby Lenskart store</p>

                            <button onClick={handleappoientment} className="store-appointment-button">
                                <a href="#_" className="store-appointment-link">
                                    Book an Appointment
                                </a>
                            </button>
                        </div>
                    </div>

                    {modal ? <BookAppoientment modal={modal} setmodal={setmodal} /> : null}
                </div>
            </div>

            <Footer />
        </>
    )
}

export default StoreHome