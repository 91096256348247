import React, { useContext, useState, useEffect } from "react";
import "./Form.css";
import Input from "./Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faL, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AppointmentNav from "../AppointmentNav";
import MyContext from "../../../../MyContext";
import Company from "../../../../../API/API_SERVISES/Company";
import EyeCheckUpAppointment from "../../../../../API/API_SERVISES/EyeCheckUpAppointment";
import swal from "sweetalert";


const Form = () => {
  const { homeEyeCheckUpData } = useContext(MyContext);
  const navigate = useNavigate();

  const [timeSlots, setTimeSlots] = useState([]);
  const [cities, setCities] = useState([])
  const [dateOptions, setDateOptions] = useState([]);
  const [stores, setStores] = useState([])

  // const [lensEyeCheckUpFee, setLensEyeCheckUpFee] = useState('Free');
  // console.log(lensEyeCheckUpFee)


  useEffect(() => {
    if (homeEyeCheckUpData) {
      const { checkUpTimeSlot, eyeCheckUpUsePincode } = homeEyeCheckUpData;

      if (checkUpTimeSlot) {
        const slots = checkUpTimeSlot.split(',');
        setTimeSlots(slots);
      }

      if (eyeCheckUpUsePincode) {
        const city = eyeCheckUpUsePincode.split(',');
        setCities(city);
      }
    }
  }, [homeEyeCheckUpData]);


  useEffect(() => {

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };


    const generateDateOptions = (limit) => {
      const dates = [];
      const currentDate = new Date();
      for (let i = 0; i < limit; i++) {
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + i);
        dates.push(formatDate(futureDate));
      }
      return dates;
    };

    setDateOptions(generateDateOptions(homeEyeCheckUpData.checkUpDateSlotLimit));
  }, [homeEyeCheckUpData]);

  const fetchData = async () => {
    try {
      const response = await Company.GetCompanyForLogin()
      // console.log(response);
      setStores(response)
    } catch (error) {
      throw new Error("Unable To Fetch Data", error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  const lensEyeCheckUpFee = `₹ ${homeEyeCheckUpData.eyeCheckUpFeeOnline ? homeEyeCheckUpData.lensEyeCheckUpFee : "Free"}`;
  const [inputValues, setInputValues] = useState({
    // lensEyeCheckUpFee: `₹ ${homeEyeCheckUpData.lensEyeCheckUpFee}`,
    selectStore: "",
    inHomeCheckUp: "",
    inStoreCheckUp: "",
    date: '',
    time: '',
    firstName: "",
    lastName: "",
    emailId: "",
    address: "",
    areaName: "",
    availableLocation: "",
    city: "",
    stateName: "",
  })


  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      // [name]: value === 'in-Home' ? 1 : 0,
      inHomeCheckUp: name === 'checkup' && value === 'in-Home' ? 1 : 0,
      inStoreCheckUp: name === 'checkup' && value === 'in-store' ? 1 : 0
    });
  };

  console.log(inputValues)

  const CreateAppointment = async (data) => {
    if(inputValues.firstName == "" || inputValues.emailId == "" || inputValues.address){
      swal({
        title: "Warning",
        text: "Please fill in all the required fields Name,Email,Address",
        icon: "warning",
        button: "OK",
        timer: 5000, // Close after 3 seconds
      });
      return;
    }
   
    try {
      const response = await EyeCheckUpAppointment.CreateEyeCheckUpAppointment(data)
      if (response.isSuccess) {
        swal("Success",{
          icon: "success",
          timer: 3000, 
          buttons: false 
        })
        setTimeout(() => {
          navigate("/")
        }, 3000)
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }



  return (
    <>
      {/* <AppointmentNav /> */}
      <div className={`main-form-appointment`}>
        <form className={`form-appointment`}>

          <div className={`nav-for-form-appointment`}>
            <div className={`div-for-heading-appointment`}>
              <h2 className={`h2-for-heading-appointment`}>
                APPOINTMENT DETAILS FOR EYE CHECK-UP
              </h2>
              <p className={`subheading-appointment`}>WE PROVIDE GOOD CUSTOMER SERVICE</p>
            </div>
            <button
              className={`btn-cross-appointment`}
              type="button"
              onClick={() => navigate("/")}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>

          <section className={`section-for-inputs-appointment`}>
            <select className={`select-appointment`}
              value={inputValues.selectStore}
              onChange={(event) => setInputValues({ ...inputValues, selectStore: event.target.value })}
            >
              <option className={`option-appointment`}>---------------- <t /><t />SELECT STORE<t /><t />----------------</option>
              {stores.map((store) => (
                <option key={store.id} value={store.printName}>
                  {store.printName.toUpperCase()}
                </option>
              ))
              }
            </select>
            <div className={`subdiv-for-lr-appointment`}>
              <div className="appointment-grid-container">
                <div className="appointment-grid-item">
                  <Input width={"90%"}
                    placeholder={"Eye check Rs"}
                    value={lensEyeCheckUpFee}

                    readOnly
                  />
                </div>
                <div className="appointment-grid-item">
                  <div className={`checkbox-appointment`}>
                    <label className={`label-appointment`}>
                      <input
                        type="radio"
                        name="checkup"
                        value="in-Home"
                        checked={inputValues.inHomeCheckUp === 1}
                        // onChange={(event) => { setInputValues({ ...inputValues, inHomeCheckUp: event.target.value }); }}
                        onChange={handleChange}
                      /> In Home CheckUp
                    </label>
                    <label className={`label-appointment`}>
                      <input
                        type="radio"
                        name="checkup"
                        value="in-store"
                        checked={inputValues.inStoreCheckUp === 1}
                        // onChange={(event) => { setInputValues({ ...inputValues, inStoreCheckUp: event.target.value }); }}
                        onChange={handleChange}
                      /> In Store Checkup
                    </label>
                  </div>
                </div>
                <div className="appointment-grid-item">
                  <select className={`select-from-appointment`}
                    value={inputValues.date}
                    onChange={(event) => setInputValues({ ...inputValues, date: Number(event.target.value) })}
                  >
                    <option value="">---Select Date---</option>
                    {dateOptions.map((date, index) => (
                      <option key={index} value={index + 1}>
                        {date}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="appointment-grid-item">
                  <select className={`select-from-appointment`}
                    value={inputValues.time}
                    onChange={(event) => setInputValues({ ...inputValues, time: Number(event.target.value) })}
                  >
                    <option value="">---Select Time Slot---</option>
                    {timeSlots.map((slot, index) => (
                      <option key={index} value={index + 1}>
                        {slot}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="appointment-grid-item">

                  <Input placeholder={"Enter First Name"} width={"90%"}
                    value={inputValues.firstName}
                    onChange={(newvalue) => setInputValues({ ...inputValues, firstName: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  <Input placeholder={"Enter Last Name"} width={"90%"}
                    value={inputValues.lastName}
                    onChange={(newvalue) => setInputValues({ ...inputValues, lastName: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  <Input placeholder={"Enter Email Id"} width={"90%"}
                    value={inputValues.emailId}
                    onChange={(newvalue) => setInputValues({ ...inputValues, emailId: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  <select className={`select-from-appointment`}
                    value={inputValues.availableLocation}
                    onChange={(event) => setInputValues({ ...inputValues, availableLocation: Number(event.target.value) })}
                  >
                    <option className={`option-appointment`}>---Avaliable Locations---</option>
                    {cities.map((city, index) => (
                      <option key={index} value={index + 1}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="appointment-grid-item">
                  <Input placeholder={"Address"} width={"90%"}
                    value={inputValues.address}
                    onChange={(newvalue) => setInputValues({ ...inputValues, address: newvalue })}
                  />
                </div>

                <div className="appointment-grid-item">
                  <Input placeholder={"Enter Area Name"} width={"90%"}
                    value={inputValues.areaName}
                    onChange={(newvalue) => setInputValues({ ...inputValues, areaName: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  <Input placeholder={"Enter City"} width={"90%"}
                    value={inputValues.city}
                    onChange={(newvalue) => setInputValues({ ...inputValues, city: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  <Input placeholder={"Enter State Name"} width={"90%"}
                    value={inputValues.stateName}
                    onChange={(newvalue) => setInputValues({ ...inputValues, stateName: newvalue })}
                  />
                </div>
              </div>
            </div>
            <p className={`p-for-form-appointment`}>Product Try At Home</p>
            <div className={`div-for-proceed-appointment`}>
              <button className={`btn-proceed-appointment`}
                onClick={(event) => {
                  event.preventDefault();
                  CreateAppointment(inputValues);
                }}
              >{homeEyeCheckUpData.eyeCheckUpFeeOnline ? "Proceed to Pay" : "Book Appointment"}</button>
            </div>
          </section>
        </form>
      </div >
    </>
  );
};

export default Form;
