/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
// Ensure that the index.css is imported globally
import BookAppoientment from "./BookAppoientment/BookAppoientment";
import "../../Css/Store.scss";
import Userintersection from "./hook/Userintersection";



function Card({ store, setselectedmap }) {
  const [modal, setmodal] = useState(false)
  const [ref, isVisible] = Userintersection(handle, {
    root: null, // Use the viewport as the root
    rootMargin: "0px",
    threshold: 0.5, // Trigger when 50% of the component is visible
  });

  function handle() {
    console.log("callback", isVisible)
  }

  useEffect(() => {
    if (isVisible) {
      console.log(store)
      setselectedmap(store?.name)
    }
  }, [isVisible])

  const handleappoientment = () => {
    setmodal(true);
  }

  const handlemapview = () => {
    setselectedmap(store.name)
  }
  return (
    // <div className="max-w-[730px]  w-full flex flex-col items-center mb-6 s  hadow-secondary-1 dark:bg-surface-dark">
    //   <div onClick={() => handlemapview()} className="w-full flex px-[15px] py-[20px] rounded-[14px] flex-col md:flex-row border border-[#dbdbea]">
    //     <div className="w-full md:w-[180px] aspect-ratio md:aspect-ratio-16-9 p-2 rounded-md flex-shrink-0 mb-4 md:mb-0">
    //       <img src={store.image} alt="Property" className="object-cover" />
    //     </div>

    //     <div className="md:ml-4 md:flex-1">
    //       <div className="mb-4">
    //         <div className="flex justify-between items-center mb-2">
    //           <h4 className="font-lenskartsans font-semibold text-base leading-6 tracking-wide text-blue-900">
    //             <a href="#" className="hover:underline">
    //               {store.name}
    //             </a>
    //           </h4>

    //           <div className="flex items-center">
    //             <div>
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 24 24"
    //                 fill="currentColor"
    //                 className="w-6 h-6 text-green-600"
    //               >
    //                 <path d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73-1.64 7.03L12 17.27z" />
    //               </svg>
    //             </div>
    //             <div className="flex items-center text-xs font-lenskartsans ml-1">
    //               <div className="font-medium">{store.rating}</div>
    //               <div className="ml-1">({store.reviews})</div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="text-left text-sm break-all text-[#66668e]">{store.address}</div>
    //         <div className="text-sm text-[#66668e]">Hours: {store.hours}</div>
    //         <div className="flex mt-4 mb-4 items-center">
    //           <div>
    //             <img
    //               src="https://www.lenskart.com/stores/svg/phone.svg"
    //               alt="call"
    //               className="w-6 h-6"
    //             />
    //           </div>
    //           <div className="ml-2 underline font-medium">
    //             <a href={`tel:${store.phone}`}>{store.phone}</a>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="flex justify-start gap-[15px] mb-2">
    //         <button className={`border border-[#000042] px-[15px] rounded-[15px]`}>
    //           <a
    //             href={`/Storehome/${store.name}`}
    //             className="relative inline-block px-4 py-2 font-medium group"
    //           >
    //             <span className="relative text-[17px]  text-black  ">
    //               Store Details
    //             </span>
    //           </a>
    //         </button>

    //         <button>
    //           <a
    //             href="#_"
    //             className="relative inline-flex bg-[#000042] text-white items-center px-[25px] py-3 overflow-hidden text-lg font-medium rounded-[15px]  "
    //           >
    //             <span className="absolute left-0 block w-full h-0 transition-all  opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
    //             <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
    //               <svg
    //                 className="w-5 h-5"
    //                 fill="none"
    //                 stroke="currentColor"
    //                 viewBox="0 0 24 24"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   stroke-linecap="round"
    //                   stroke-linejoin="round"
    //                   stroke-width="2"
    //                   d="M14 5l7 7m0 0l-7 7m7-7H3"
    //                 ></path>
    //               </svg>
    //             </span>
    //             <span onClick={handleappoientment} className="relative">Book an Appointment</span>
    //           </a>
    //         </button>
    //       </div>
    //       <div className="flex justify-center py-[10px] gap-[10px] bg-[#bff6f2] p-1">
    //         <div className="font-[500] text-[14px]">Free Anti Fog Spray</div>
    //         <div className="font-[500] text-[14px]">• Free Eye Test</div>
    //         <div className="font-[500] text-[14px]">• Free Repair</div>
    //       </div>
    //     </div>
    //   </div>

    //   {
    //     modal ? <BookAppoientment modal={modal} setmodal={setmodal} /> : null
    //   }
    // </div>

    <div  ref={ref} className="store-container">
      <div onClick={() => handlemapview()} className="store-content">
        <div className="store-image-container">
          <img src={store.image} alt="Property" className="store-image" />
        </div>

        <div className="store-details">
          <div className="store-header">
            <div className="store-title">
              <h4 className="store-name">
                <a href="#" className="store-name-link">
                  {store.name}
                </a>
              </h4>
              <div className="store-rating">
                <div className="store-rating-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="store-rating-star"
                  >
                    <path d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73-1.64 7.03L12 17.27z" />
                  </svg>
                </div>
                <div className="store-rating-text">
                  <div className="store-rating-value">{store.rating}</div>
                  <div className="store-reviews">({store.reviews})</div>
                </div>
              </div>
            </div>
            <div className="store-address">{store.address}</div>
            <div className="store-hours">Hours: {store.hours}</div>
            <div className="store-contact">
              <img src="https://www.lenskart.com/stores/svg/phone.svg" alt="call" className="store-phone-icon" />
              <div className="store-phone-number">
                <a href={`tel:${store.phone}`}>{store.phone}</a>
              </div>
            </div>
          </div>
          <div className="store-buttons">
            {/* <button className="store-details-button"> */}
              <a href={`/Storehome/${store.name}`} className="store-details-link store-details-button">
                Store Details
              </a>
            {/* </button> */}
            <button onClick={handleappoientment} className="store-appointment-button">
              <a href="#_" className="store-appointment-link">
                Book an Appointment
              </a>
            </button>
          </div>
          <div className="store-offers">
            <div className="offer-item">Free Anti Fog Spray</div>
            <div className="offer-item">• Free Eye Test</div>
            <div className="offer-item">• Free Repair</div>
          </div>
        </div>
      </div>

      {modal ? <BookAppoientment modal={modal} setmodal={setmodal} /> : null}
    </div>

  );
}

export default Card;
