import React, { useState } from "react";
import Prescription from "../../Assets/Image_lc/prescription.png"
import call from "../../Assets/Image_lc/call back.jpg";
import ModelPrescriptionManually from "./ModelPrescriptionManually";
import ModelSavedPrescription from "./ModelSavedPrescription";
import ModelUploadPrescription from "./ModelUploadPrescription";
import ModelRequestACallBack from "./ModelRequestACallBack";
// import MyContext from "./MyContext";

const OrderBox_Lc = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [isModalOpenPower, setIsModalOpenPower] = useState(false);

    const toggleClass = () => {
        setIsClicked(!isClicked);
    };

    const tableData = [
        {
            left: {
                sph: -1.50,
                cyl: -0.75,
                axis: 90,
                add: 1.00,
                pupil: 2.5
            },
            right: {
                sph: -1.25,
                cyl: -0.50,
                axis: 85,
                add: 1.00,
                pupil: 2.5
            }
        },
        // Additional rows here...
    ];


    const [isSubmitted, setIsSubmitted] = useState(false);

  // Function to be passed down to child to update the state
  const handleSubmitEyePower = () => {
    setIsSubmitted(true); // When the user clicks the submit button
  };
    /****************************************************** */

    const [isModalOpenManually, setIsModalOpenManually] = useState(false);

    const toggleModalManually = () => {
        setIsModalOpenManually(!isModalOpenManually);
    };

    const closeModalManually = () => {
        setIsModalOpenManually(false);
    };

    /************************************************ */

    const [isModalOpenSaved, setIsModalOpenSaved] = useState(false);

    const toggleModalSaved = () => {
        setIsModalOpenSaved(!isModalOpenSaved);
    };

    const closeModalSaved = () => {
        setIsModalOpenSaved(false);
    };

    /************************************************ */

    const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);

    const toggleModalUpload = () => {
        setIsModalOpenUpload(!isModalOpenUpload);
    };

    const closeModalUpload = () => {
        setIsModalOpenUpload(false);
    };


    /****************************************************** */

    const [isModalOpenCallBack, setIsModalOpenCallBack] = useState(false);

    const toggleModalCallBack = () => {
        setIsModalOpenCallBack(!isModalOpenCallBack);
    };

    const closeModalCallBack = () => {
        setIsModalOpenCallBack(false);
    };

    /*************************************************** */

    const toggleModalPower = () => {
        setIsModalOpenPower(prevState => !prevState);
    }

    return (
        <div>
            <div

                className={`custom-div my-order-table order-mybox-height`}
            >
                <div className="order-box-top-top">
                    <div className="order-box-top">
                        <div className="ord-detail-admin">
                            <div className="flex-ord-date-time">
                                <div className="flex-ord-no">
                                    <p className="ordertxt-1-order">Order No </p>
                                    <p className="ordertxt-1-ordno">:</p>
                                    <p className="ordertxt-1-ordno">1289843047</p>
                                </div>
                                <div className="flex-ord-no">
                                    <p className="ordertxt-1-order">Order Date </p>
                                    <p className="ordertxt-1-ordno">:</p>
                                    <p className="ordertxt-1-ordno">21 Oct  11:44</p>
                                </div>
                            </div>
                            {/* <p className="ordertxt-2">
                Service Type : Table | Table No : PERGOLA 2
              </p>
              <p className="ordertxt-2">Delivery Date : 02/11/2023</p>
              <p className="ordertxt-2">Delivery Time Slot : 17:00 To 17:30</p> */}
                        </div>

                        <div

                            className="order-detail-box-list"
                        >
                            <p className="ordertxt-1-order">Total Price </p>
                            <p className="ordertxt-1-ordno">:</p>
                            <p className="ordertxt-1-ordno">₹1500</p>
                        </div>
                    </div>

                    <div className="order-box-top pay-ord-border">
                        <div className="ord-detail-admin">
                            <div className="">
                                <div className="Complete-payment">Complete Your Payment</div>
                                <div className="processed-pymt">Order will be processed after payment</div>
                            </div>
                        </div>
                        <div

                            className="order-detail-box-list-pay"
                        >
                            <button className="btn-pay-now">PAY NOW</button>
                        </div>
                    </div>
                    <div>

                        <div
                            style={{
                                borderRadius: "15px",
                                boxShadow: "none",
                                width: "100%",
                                padding: "0",
                                marginBottom: "0"
                            }}
                            className=""
                        >
                            <label className="title"></label>
                            <div className="flex-stop-pay">
                                <div className="elipse-stop-triang">
                                    <i class="fa-solid fa-triangle-exclamation triangle-stop"></i>
                                </div>
                                <div className="power-submission-pay">
                                    <div className="sub-payment">Payment & Power Submission Pending</div>
                                    <div className="processed-pymt">Complete your payment & submit power to process order</div>
                                </div>
                            </div>
                            <div className="favourite-box-container">
                                <div className="fav-box-width">
                                    <div className="flex-ord-sold">
                                        <div className="review-box-image order-image-box">
                                            <img className="favourite-img fav-image-ord"
                                                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="review-box-detail item-details-width-ord">
                                            <div className="review-box-detail-line-1">
                                                <div className="name-top-item">

                                                    Lenskart Hustlr Eyeglasses
                                                    + Hydrophobic Anti-Glare

                                                </div>
                                            </div>
                                            <div className="review-box-detail-line-2">
                                                <div className="lens-txt">
                                                    Lens :{" "}
                                                    <div className="review-product-name">
                                                        Sold by Lenskart Solutions Pvt Ltd.
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "60px",
                                                    }}
                                                >
                                                    {" "}
                                                </div>
                                            </div>
                                            <p className="review-price-item">
                                                <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex-add-power">
                                        <div>Submit "POWER" to complete order</div>
                                        <div className="flex-chevron-icon" onClick={toggleModalPower}>
                                            <div className="Add-Power-ord">ADD POWER</div>
                                            <div> <i
                                                className={`fa-solid ${isModalOpenPower ? 'fa-chevron-up' : 'fa-chevron-down'} icon-downUp-chev`}
                                            ></i></div>
                                        </div>
                                    </div>
                                    {isModalOpenPower && (
                                        <div>
                                            <div className="flex-add-manually-prescription">
                                                <div className="Prescription-manually" onClick={toggleModalManually}>
                                                    <div className="Manually-pres-cart5">
                                                        <img className="prescription-image" src={Prescription} alt="" />
                                                    </div>
                                                    <div className="manually-name-prescription">Enter Prescription Manually</div>
                                                </div>

                                                <div className="Prescription-manually" onClick={toggleModalSaved}>
                                                    <div className="Manually-pres-cart5">
                                                        <img className="prescription-image" src={Prescription} alt="" />
                                                    </div>
                                                    <div className="manually-name-prescription">Use Saved Prescription</div>
                                                </div>
                                                <div className="Prescription-manually" onClick={toggleModalUpload}>
                                                    <div className="Manually-pres-cart5">
                                                        <img className="prescription-image" src={Prescription} alt="" />
                                                    </div>
                                                    <div className="manually-name-prescription">Upload Prescription</div>
                                                </div>
                                            </div>
                                            <div className="flex-add-manually-prescription">
                                                <div className="Prescription-manually" onClick={toggleModalCallBack}>
                                                    <div className="Manually-pres-cart5">
                                                        <img className="prescription-image" src={call} alt="" />
                                                    </div>
                                                    <div className="manually-name-prescription">Request A Call Back</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {isModalOpenManually && (
                                        <div className="modal-back">
                                            <div

                                                style={{ zIndex: "5" }}
                                                className="OpticalField-modal increase_modal_cart5_manually ">
                                                <div className="model-manually-pres" style={{ padding: "5px" }}>
                                                    <div className="Modal-Title-Lc ">
                                                        <label
                                                            className="close_modal-theme3"
                                                            onClick={closeModalManually}
                                                        >
                                                            <i class="fa-solid fa-xmark"></i>
                                                        </label>
                                                    </div>
                                                    <div className="technical-scroll-detail model-manually">
                                                        {/* <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.longDescription }} /> */}
                                                        <ModelPrescriptionManually onContinue={handleSubmitEyePower} isSubmitted={isSubmitted}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {isModalOpenSaved && (
                                        <div className="modal-back">
                                            <div

                                                style={{ zIndex: "5" }}
                                                className="OpticalField-modal increase_modal_cart5"
                                            >
                                                <div className="model-manually-pres" style={{ padding: "5px" }}>
                                                    <div className="Modal-Title-Lc ">
                                                        <label
                                                            className="close_modal-theme3"
                                                            onClick={closeModalSaved}
                                                        >
                                                            <i class="fa-solid fa-xmark"></i>
                                                        </label>
                                                    </div>
                                                    <div className="technical-scroll-detail model-Saved-prescription">
                                                        {/* <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.longDescription }} /> */}
                                                        <ModelSavedPrescription />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {isModalOpenUpload && (
                                        <div className="modal-back">
                                            <div

                                                style={{ zIndex: "5" }}
                                                className="OpticalField-modal increase_modal_cart5_manually"
                                            >
                                                <div className="model-manually-pres" style={{ padding: "5px" }}>
                                                    <div className="Modal-Title-Lc ">
                                                        <label
                                                            className="close_modal-theme3"
                                                            onClick={closeModalUpload}
                                                        >
                                                            <i class="fa-solid fa-xmark"></i>
                                                        </label>
                                                    </div>
                                                    <div className="technical-scroll-detail model-upload-padd">
                                                        {/* <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.longDescription }} /> */}
                                                        <ModelUploadPrescription />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {isModalOpenCallBack && (
                                        <div className="modal-back">
                                            <div

                                                style={{ zIndex: "5" }}
                                                className="OpticalField-modal increase_modal_cart5_manually ">
                                                <div className="model-manually-pres" style={{ padding: "5px" }}>
                                                    <div className="Modal-Title-Lc ">
                                                        <label
                                                            className="close_modal-theme3"
                                                            onClick={closeModalCallBack}
                                                        >
                                                            <i class="fa-solid fa-xmark"></i>
                                                        </label>
                                                    </div>
                                                    <div className="technical-scroll-detail model-manually">
                                                        {/* <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.longDescription }} /> */}
                                                        <ModelRequestACallBack />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {isSubmitted && (
                                    <div className="Prescription-show-table">
                                        <div className="cutomer-name-order">Techcherry</div>
                                        <div className="overflow-table-pres">
                                            <table className="table-prescription-data-ord">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th className="table-power-font">Spherical(SPH)</th>
                                                        <th className="table-power-font">Cylindrical(CYL)</th>
                                                        <th className="table-power-font">Axis(0-180)</th>
                                                        <th className="table-power-font">Additional Power(AP)</th>
                                                        <th className="table-power-font">Pupil Distance(PD)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.map((row, index) => (
                                                        <>
                                                            <tr key={`left-${index}`}>
                                                                <td>LEFT</td>
                                                                <td className="prescription-number">{row.left.sph}</td>
                                                                <td className="prescription-number">{row.left.cyl}</td>
                                                                <td className="prescription-number">{row.left.axis}</td>
                                                                <td className="prescription-number">{row.left.add}</td>
                                                                <td className="prescription-number">{row.left.pupil}</td>
                                                            </tr>

                                                            <tr key={`right-${index}`}>
                                                                <td>RIGHT</td>
                                                                <td className="prescription-number">{row.right.sph}</td>
                                                                <td className="prescription-number">{row.right.cyl}</td>
                                                                <td className="prescription-number">{row.right.axis}</td>
                                                                <td className="prescription-number">{row.right.add}</td>
                                                                <td className="prescription-number">{row.right.pupil}</td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    )}

                                    <div className="flex-order-delivery">
                                        <div className="order-process">
                                            <div className="Green-Line"></div>
                                            <div className="order-start-icon"><i class="fa-solid fa-cart-plus"></i></div>
                                            <div className="process-by-order order-color">Order Created</div>
                                        </div>

                                        <div className="order-process">
                                            <div className="Green-Line-dispatch"></div>
                                            <div className="circle-icon-ord"><i class="fa-regular fa-circle"></i></div>
                                            <div className="process-by-order">Prepare For Dispatch</div>
                                        </div>
                                        <div className="order-process">
                                            <div className="Green-Line-way"></div>
                                            <div className="circle-icon-ord"><i class="fa-regular fa-circle"></i></div>
                                            <div className="process-by-order">On the Way</div>
                                        </div>
                                        <div className="order-process">
                                            <div className="circle-icon-ord"><i class="fa-regular fa-circle"></i></div>
                                            <div className="process-by-order">Delivered</div>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <div>
                                            <div></div>
                                            <div>Enter Priscription Manually</div>
                                        </div>
                                    </div> */}
                                </div>
                                <div>
                                    <div className="flex-ord-details">
                                        <div className="order-details-open" onClick={toggleClass}>Order Details</div>
                                    </div>
                                    {isClicked && (
                                        <div className="order-box-top">
                                            <div className="address-detail-width">
                                                <p className="ordertxt-1-delivery">Delivery Address</p>
                                                <p className="ordertxt-1-name">Techcherry IT Solution</p>
                                                <p className="ordertxt-2-address">
                                                    Adarsh Nagar
                                                </p>
                                                <p className="ordertxt-2-address">Durg - 492001</p>
                                                <p className="ordertxt-2-address">Chhattisgarh, India</p>
                                                <p className="ordertxt-2-email">techcherry@gmail.com</p>
                                                <p className="ordertxt-2-number">+91-9876543210</p>
                                            </div>
                                            <div
                                                className="address-detail-width"
                                            >
                                                <p className="ordertxt-1-delivery">Price Breakup:</p>
                                                <div className="flex-subtotal">
                                                    <p className="ordertxt-1-subtotal">Subtotal</p>
                                                    <p className="ordertxt-1-subtotal">₹1500</p>
                                                </div>
                                                <div className="flex-autodiscount">
                                                    <p className="ordertxt-1-subtotal">Auto Discount</p>
                                                    <p className="ordertxt-1-subtotal">-₹500</p>
                                                </div>
                                                <div >
                                                    <div className="flex-subtotal-netamt">
                                                        <p className="ordertxt-1-Amount">
                                                            Net Amount
                                                        </p>
                                                        <p className="ordertxt-1-Amount">
                                                            ₹1500
                                                        </p>
                                                    </div>
                                                    <div className="flex-subtotal">
                                                        <p className="ordertxt-1-Convenience">
                                                            Convenience Fees
                                                        </p>
                                                        <p className="ordertxt-1-Convenience">
                                                            Free
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex-subtotal-netamt">
                                                    <p className="ordertxt-1-Amount">
                                                        Total
                                                    </p>
                                                    <p className="ordertxt-1-Amount">
                                                        ₹1500
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* <div className="favourite-box">
                                    <div className="review-box-image">
                                        <img className="favourite-img"
                                            src="https://happychashma.com/shop/images/product/Green_Year_9017_Metal_Half_Rim_52_Rectangle_Black_502_1.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="review-box-detail">
                                        <div className="review-box-detail-line-1">
                                            <div className="review-box-name">
                                                Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                                                Eyeglasses....
                                            </div>
                                            <div className="review-box-section">
                                                <img className="fa-trash" src={dlt} alt="" />
                                            </div>
                                        </div>
                                        <div className="review-box-detail-line-2">
                                            <div className="lens-txt">
                                                Lens :{" "}
                                                <div className="review-product-name">
                                                    Black Full Frame Eyeglasses
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "60px",
                                                }}
                                            >
                                                {" "}
                                                <p className="review-price">
                                                    <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default OrderBox_Lc;
