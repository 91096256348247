import React, { useEffect, useState } from 'react'
import paymentDone from "../Assets/Images/order.png"
import paymentFail from "../Assets/Images/accept.png"
import PayStack from '../../API/API_SERVISES/PayStack'
import { Link } from 'react-router-dom'


const PaymentResponse = () => {

    const [orderdetails, setOrderDetails] = useState({})

    useEffect(() => {
        // var data = {
        //     "status": true,
        //     "message": "Verification successful",
        //     "data": {
        //         "id": 4099260516,
        //         "domain": "test",
        //         "status": "success",
        //         "reference": "re4lyvq3s3",
        //         "receipt_number": null,
        //         "amount": 40333,
        //         "message": null,
        //         "gateway_response": "Successful",
        //         "paid_at": "2024-08-22T09:15:02.000Z",
        //         "created_at": "2024-08-22T09:14:24.000Z",
        //         "channel": "card",
        //         "currency": "NGN",
        //         "ip_address": "197.210.54.33",
        //         "metadata": "",
        //         "log": {
        //             "start_time": 1724318098,
        //             "time_spent": 4,
        //             "attempts": 1,
        //             "errors": 0,
        //             "success": true,
        //             "mobile": false,
        //             "input": [],
        //             "history": [
        //                 {
        //                     "type": "action",
        //                     "message": "Attempted to pay with card",
        //                     "time": 3
        //                 },
        //                 {
        //                     "type": "success",
        //                     "message": "Successfully paid with card",
        //                     "time": 4
        //                 }
        //             ]
        //         },
        //         "fees": 10283,
        //         "fees_split": null,
        //         "authorization": {
        //             "authorization_code": "AUTH_uh8bcl3zbn",
        //             "bin": "408408",
        //             "last4": "4081",
        //             "exp_month": "12",
        //             "exp_year": "2030",
        //             "channel": "card",
        //             "card_type": "visa ",
        //             "bank": "TEST BANK",
        //             "country_code": "NG",
        //             "brand": "visa",
        //             "reusable": true,
        //             "signature": "SIG_yEXu7dLBeqG0kU7g95Ke",
        //             "account_name": null
        //         },
        //         "customer": {
        //             "id": 181873746,
        //             "first_name": null,
        //             "last_name": null,
        //             "email": "demo@test.com",
        //             "customer_code": "CUS_1rkzaqsv4rrhqo6",
        //             "phone": null,
        //             "metadata": null,
        //             "risk_action": "default",
        //             "international_format_phone": null
        //         },
        //         "plan": null,
        //         "split": {},
        //         "order_id": null,
        //         "paidAt": "2024-08-22T09:15:02.000Z",
        //         "createdAt": "2024-08-22T09:14:24.000Z",
        //         "requested_amount": 30050,
        //         "pos_transaction_data": null,
        //         "source": null,
        //         "fees_breakdown": null,
        //         "connect": null,
        //         "transaction_date": "2024-08-22T09:14:24.000Z",
        //         "plan_object": {},
        //         "subaccount": {}
        //     }
        // }
        FetchOrderDetails()
    }, [])

    const FetchOrderDetails = async () => {
        debugger
        var PaymentdataInString = localStorage.getItem("PayStackPayment")
        var data = JSON.stringify(PaymentdataInString)

        var dataToSend = {
            reference: data.reference || "b0k5is059k"
        }

        var orderdata = await PayStack.VerifyPayment(dataToSend)
        debugger
        if (orderdata.data.status === true) {
            setOrderDetails(orderdata.data)
        }
    }

    return (<>
        {orderdetails && Object.keys(orderdetails).length > 0 && (
            <div className='PaymentResponse'>
                {orderdetails?.data.status == "success" ? (
                    <img src={paymentDone} alt="" />
                ) : (
                    <img src={paymentFail} alt="" />
                )}
                <div className='paymentHeading'>
                    <h3>Payment Status : {(orderdetails?.data.status)?.toUpperCase()}</h3>
                    <p>Amount</p>
                    <h5>{orderdetails?.data.amount} KEN</h5>
                </div>
                <div className='paymentDetailsBox'>
                    <div className='paymentDetailsBox-inner' >
                        <div>
                            <label className='PaymentBoxInnerheading'>Amount Paid :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.amount} KES</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Charge Money :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.fees} KES</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Paymeny Method :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.channel}</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Currency :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.currency}</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Email :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.customer.email}</label>
                        </div>
                        <div>
                            <label className='PaymentBoxInnerheading'>Mobile :</label>
                            <label className='PaymentBoxInnerRes'>{orderdetails?.data.customer.phone}</label>
                        </div>
                    </div>
                    <div className='PaymentBoxButtonBox'>
                        <Link to="/" style={{ display: orderdetails?.data.status == "success" ? "" : "none" }} className='paymentDetailsBox-button print-btn-clr'>Print <i style={{ margin: "2px 0px 0px 6px" }} class="fa-solid fa-print"></i></Link>
                        <Link to="/" style={{ backgroundColor: orderdetails?.data.status == "success" ? "" : "#de5605" }} className='paymentDetailsBox-button'>Back to Homepage</Link>
                    </div>

                </div>


            </div>
        )
        }
        <h2 style={{ textAlign: "center", color: "gray", marginBottom: "200px" }}>We’ve received your Order. Your Order number is 252.</h2>

    </>
    )
}

export default PaymentResponse